<template>
    <div>
        <table class="table">
            <tr>
              <td>
                <video v-for="x in amount" :key="x" :src="`/daylies/${this.day}/${x}.mp4#t=0.1`" controls class="videos" preload="metadata"></video>
                <img v-for="y in jpg_amount" :key="y" :src="`/daylies/${this.day}/${y}.jpg`">
              </td>
            </tr>
          </table>
    </div>
</template>

<script>
import { useCounterStore } from '@/stores/store'
export default {
    name: "specificArchive",
    data(){
        return {
            day: '',
            amount: 0,
            jpg_amount : 0,
        }
    },
    mounted(){
      const counter = useCounterStore()
        this.amount = counter.amount
        this.day = counter.date
        this.jpg_amount = counter.jpg_amount
    }   
}
</script>

<style scoped>

</style>