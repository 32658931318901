import { createApp } from 'vue'
import App from './App.vue'
import { createWebHistory, createRouter } from "vue-router";
import { createPinia } from "pinia";

import Home from './components/monthlyVideos.vue';
import archives from './components/archives.vue';
import specificArchive from './components/specificArchive.vue';


const router = new createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', name:"Home", component: Home },
    { path: '/archives',name:"archivesNothing", component: archives },
    { path: '/specificArchive',name:"specificArchive", component: specificArchive },
  ]
});

createApp(App).use(router).use(createPinia()).mount('#app')
