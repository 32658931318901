<template>
  <div class="div">
    <fl-footer class="footer"></fl-footer>
    <router-view/>
    <span class="span">gentoncrack.be {{ new Date().getFullYear() }}</span>
  </div>
</template>

<script>
import FlFooter from './components/fl-footer.vue';


export default {
  name: "App",
  components:{
    FlFooter
  },
  data() {
    return {
      sorted: [],
    };
  },
};
</script>

<style>
#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}
.div {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
body{
  min-height: 100vh;
  flex-direction: column;
}
.footer{
  bottom: 0;
  width: 100%;
  height: 60px;   /* Height of the footer */
}
.span{
  position: fixed;
  bottom: 0;
}
</style>
