<template>
      <div class="div">
        <p>Watch all stories from gentstudent per day from oktober 2022:  </p>
        <router-link to="/archives"><mybutton type="danger" say="archives"/></router-link>
      </div>
  </template>
  
  <script>
  import mybutton from './mybutton.vue';
  export default {
    name: "fl-footer",
    components:{
      mybutton
    },
    data () {
      return {
        
      }
    }
  }
  </script>

<style scoped>
.div {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.span{
  text-align:center;
  margin-right: 20px;
}
</style>
  