<template>
  <table class="s">
    <tr v-for="n in updatedfolders" :key="n">
      <td v-for="x in n" :key="x">
        <div class="div">
          <archiveButton  :jpg_amount="x.jpg_amount" :day=x.date type="success"
            :amount=x.amount :say=x.date class="archiveButton"/>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>

import archiveButton from './archiveButton.vue';

export default {
  name: 'archivesNothing',
  data() {
    return {
      updatedfolders:[],
      folders: [
        { date: "13-08-2023", amount: 5, jpg_amount: 0 }, 
        { date: "25-08-2023", amount: 5, jpg_amount: 0 }, 
        { date: "27-08-2023", amount: 4, jpg_amount: 2 }, 
        { date: "28-08-2023", amount: 4, jpg_amount: 8 },
        { date: "31-08-2023", amount: 3, jpg_amount: 10 },
        { date: "1-09-2023", amount: 2, jpg_amount: 1 },
        { date: "3-09-2023", amount: 3, jpg_amount: 1 },
        { date: "4-09-2023", amount: 3, jpg_amount: 1 },
        { date: "07-09-2023", amount: 4, jpg_amount: 3 },
        { date: "08-09-2023", amount: 4, jpg_amount: 1 },
        { date: "18-09-2023", amount: 1, jpg_amount: 3 },
        { date: "21-09-2023", amount: 3, jpg_amount: 0 },
        { date: "02-10-2023", amount: 5, jpg_amount: 5 },
        { date: "22-10-2023", amount: 1, jpg_amount: 6 },
        { date: "04-12-2023", amount: 7, jpg_amount: 5 },
        { date: "05-12-2023", amount: 2, jpg_amount: 4 },
        { date: "06-12-2023", amount: 4, jpg_amount: 4 },
        { date: "07-12-2023", amount: 5, jpg_amount: 10 },
        { date: "11-12-2023", amount: 1, jpg_amount: 0 },
        { date: "13-12-2023", amount: 3, jpg_amount: 3 },
        { date: "14-12-2023", amount: 3, jpg_amount: 3 },
        { date: "20-12-2023", amount: 2, jpg_amount: 3 },
        { date: "15-12-2023", amount: 1, jpg_amount: 1 },
        { date: "16-12-2023", amount: 2, jpg_amount: 3 },
        { date: "18-12-2023", amount: 1, jpg_amount: 1 },
        { date: "21-12-2023", amount: 3, jpg_amount: 3 },
        { date: "22-12-2023", amount: 0, jpg_amount: 2 },
        { date: "25-12-2023", amount: 2, jpg_amount: 2 },
        { date: "26-12-2023", amount: 2, jpg_amount: 0 },
        { date: "29-12-2023", amount: 4, jpg_amount: 2 },
        { date: "30-12-2023", amount: 3, jpg_amount: 5 },
        { date: "31-12-2023", amount: 4, jpg_amount: 1 },
        { date: "01-01-2024", amount: 2, jpg_amount: 2 },
        { date: "02-01-2024", amount: 8, jpg_amount: 8 },
        { date: "04-01-2024", amount: 2, jpg_amount: 1 },
        { date: "05-01-2024", amount: 2, jpg_amount: 0 },
        { date: "07-01-2024", amount: 2, jpg_amount: 1 },
        { date: "08-01-2024", amount: 1, jpg_amount: 0 },
        { date: "09-01-2024", amount: 1, jpg_amount: 0 },
        { date: "10-01-2024", amount: 4, jpg_amount: 1 },
        { date: "12-01-2024", amount: 2, jpg_amount: 2 },
        { date: "15-01-2024", amount: 1, jpg_amount: 2 },
        { date: "17-01-2024", amount: 3, jpg_amount: 6 },
        { date: "20-01-2024", amount: 2, jpg_amount: 2 },
        { date: "21-01-2024", amount: 1, jpg_amount: 0 },
        { date: "22-01-2024", amount: 4, jpg_amount: 2 },
        { date: "23-01-2024", amount: 4, jpg_amount: 2 },
        { date: "25-01-2024", amount: 4, jpg_amount: 1 },
        { date: "22-02-2024", amount: 1, jpg_amount: 3 },
        { date: "01-02-2024", amount: 11, jpg_amount: 8 },
        { date: "15-02-2024", amount: 4, jpg_amount: 7 },
        { date: "16-02-2024", amount: 12, jpg_amount: 8 },
        { date: "18-02-2024", amount: 8, jpg_amount: 3 },
        { date: "05-03-2024", amount: 3, jpg_amount: 2 },
        { date: "23-03-2024", amount: 2, jpg_amount: 6 },
        { date: "24-03-2024", amount: 1, jpg_amount: 2 },
        { date: "27-03-2024", amount: 12, jpg_amount: 8 },

      ]
    }
  },
  mounted(){
    console.log(this.folders.length)
    while(this.folders.length){
    this.updatedfolders.push(this.folders.splice(0,5));
    
  }
  console.log(this.updatedfolders)
  },
  components: {
    archiveButton
  }
}
</script>

<style scoped>
.div {
  flex-grow: 1;
  display: grid;
  width: 100%;
}
.s{
  display: block;
}
</style>