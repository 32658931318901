<template>
  <div class="div">
    <H1>Gentstudent tops stories:</H1>
    <table class="table">
      <tr>
        <td>
          <h1>march - 2024</h1>
          <video v-for="x in 9" :key="x" :src="`/february-2024/${x}.mp4#t=0.1`" controls class="videos" preload="metadata"></video>
          <h1>february - 2024</h1>
          <video v-for="x in 12" :key="x" :src="`/february-2024/${x}.mp4#t=0.1`" controls class="videos" preload="metadata"></video>
          <h1>january - 2024</h1>
          <video v-for="x in 12" :key="x" :src="`/january-2024/${x}.mp4#t=0.1`" controls class="videos" preload="metadata"></video>
          <h1>September</h1>
          <video v-for="x in 14" :key="x" :src="`/september/${x}.mp4#t=0.1`" controls class="videos" preload="metadata"></video>
          <h1>August</h1>
          <video v-for="x in 12" :key="x" :src="`/august/${x}.mp4#t=0.1`" controls class="videos" preload="metadata"></video>
          <h1>juli</h1>
          <video v-for="x in 9" :key="x" :src="`/juli/${x}.mp4#t=0.1`" controls class="videos" preload="metadata"></video>
          <h1>juni</h1>
          <video v-for="x in 5" :key="x" :src="`/june/${x}.mp4#t=0.1`" controls class="videos" preload="metadata"></video>
          <h1>May</h1>
          <video v-for="x in 12" :key="x" :src="`/May/${x}.mp4#t=0.1`" controls class="videos" preload="metadata"></video>

          
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  name: "monthlyVideos"
}
</script>
<style>
.table {   
margin-left: auto;
margin-right: auto;
width: 95vw;
text-align:center;
}

.videos {
  width: 30vw;
  border: 5px white;
  box-shadow: 2px 2px;
  margin: 4px;
}

.div {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 620px) {
  .videos {
    width: 90vw;
    border: 5px white;
    box-shadow: 2px 2px;
    margin: 4px;
  }
}
</style>