import { defineStore } from 'pinia'

export const useCounterStore = defineStore('counter', {
  state: () => {
    return { date: '', amount: 0, jpg_amount: 0  }
  },
  actions: {
    change_date(date) {
      this.date = date;
    },
    change_amount(amount) {
        this.amount = amount;
      },    
      change_jpg_amount(jpg_amount) {
        this.jpg_amount = jpg_amount;
      },
  },
})