<template>
    <div>
        <button class="normal" :jpg_amount="jpg_amount" :say="say" :day="day" :class="type" :amount="amount" :disabled="disabled" @click="callback($event)">
            <slot>{{say}}</slot>
        </button>
    </div>
</template>

<script>
import { useCounterStore } from '@/stores/store'
export default {
    name: "archive-button",
    props: {
    type: String,
    disabled: Boolean,
    say: String,
    day: String,
    amount: Number,
    jpg_amount: Number
  },
  data() {
    return{
        label: 'danger'
    }
  },
  methods: {
    callback() {
      const counter = useCounterStore()
      counter.change_amount(this.amount)
      counter.change_date(this.day);
      counter.change_jpg_amount(this.jpg_amount)
      this.$router.push({ name: 'specificArchive'})
    },
    toggleLable(e) {
      console.log(e.target.tagName);
      if (this.label == 'danger') {
        this.label = "danger-changed";
      } else {
        this.label = "danger";
      }
  }}
}
</script>

<style scoped>
button {
  padding: 10px 20px;
  width: 115px;
  border: 1px solid #ddd;
  color: #333;
  background-color:#fff;
  border-radius: 4px;
  font-size: 14px;
  font-family: arail;
  cursor: pointer;
  &[disabled]{
    cursor: not-allowed;
  }
  &.danger {
    background-color: #ff4949;
    color: #fff;
  }
  &.success {
    background-color: #C4A484;
    color: #fff;
  }
  &.info {
    background-color: #50bfff;
    color: #fff;
  }
}
</style>