<template>
    <div>
        <button class="normal" :say="say" :class="type" :disabled="disabled" @click="callback($event)">
            <slot>{{say}}</slot>
        </button>
    </div>
</template>

<script>
export default {
    name: "my-button",
    props: {
    type: String,
    disabled: Boolean,
    say: String
  },
  data() {
    return{
        label: 'danger'
    }
  },
  methods: {
    callback(e) {
      this.$emit('click', e);
    },
    toggleLable(e) {
      console.log(e.target.tagName);
      if (this.label == 'danger') {
        this.label = "danger-changed";
      } else {
        this.label = "danger";
      }
  }}
}
</script>

<style scoped>
button {
  padding: 10px 20px;
  border: 1px solid #ddd;
  color: #333;
  background-color:#fff;
  border-radius: 4px;
  font-size: 14px;
  font-family: arail;
  cursor: pointer;
  &[disabled]{
    cursor: not-allowed;
  }
  &.danger {
    background-color: #ff4949;
    color: #fff;
  }
  &.success {
    background-color: #C4A484;
    color: #fff;
  }
  &.info {
    background-color: #50bfff;
    color: #fff;
  }
}
</style>